// Import all of Bootstrap's JS
import '@indicatrix/elm-chartjs-webcomponent';
import * as bootstrap from 'bootstrap';
import Chart from 'chart.js/auto';
import { Elm } from '../src/Main.elm';

const acessTokenKey = 'esses-token-access';
const refreshTokenKey = 'esses-token-refresh';
const politicaCokie = 'cookie_aux';
const anuncioCokie = 'anuncio_cookie';
const hideOpinionResearch = 'hide-opinion-research';

var app = Elm.Main.init({
    node: document.querySelector('main'),
    flags: {
        "be_url": process.env.BE_URL,
        "fe_url": process.env.FE_URL,
        "access_token": localStorage.getItem(acessTokenKey),
        "refresh_token": localStorage.getItem(refreshTokenKey),
        "politica_cookie": getCookie(politicaCokie),
        "hide_opinion_research": getCookie(hideOpinionResearch),
        "anuncio_cookie": getCookie(anuncioCokie),
        "exibe_anuncio_dashboard": process.env.EXIBE_ANUNCIO_DASHBOARD
    }
});

app.ports.copyToClipboard.subscribe((text) => {
    navigator.clipboard.writeText(text);
});

app.ports.showNotification.subscribe((elementId) => {
    let myToastEl = document.getElementById(elementId);
    // Returns a Bootstrap toast instance
    let myToast = bootstrap.Toast.getOrCreateInstance(myToastEl);
    if (myToast != null)
        myToast.show();
});

app.ports.sendAccessTokenToStorage.subscribe((token) => {
    localStorage.setItem(acessTokenKey, token)
});

app.ports.sendRefreshTokenToStorage.subscribe((token) => {
    localStorage.setItem(refreshTokenKey, token)
});

app.ports.clearAccessTokenFromStorage.subscribe((token) => {
    localStorage.removeItem(acessTokenKey, token)
});

app.ports.clearRefreshTokenFromStorage.subscribe((token) => {
    localStorage.removeItem(refreshTokenKey, token)
});

app.ports.selectElementValue.subscribe((elementId) => {
    let element = document.getElementById(elementId);
    if (element != null) {
        if (element.value == "") {
            element.select();
        }
    }
});

app.ports.redirectNewTab.subscribe((url) => {
    window.open(url, "_blank")
});

// criar novo setCookie para anuncio

app.ports.setCookieAnuncioMaster.subscribe((objeto) => {
    let key, value, hoursToExpire;
    [key, value, hoursToExpire] = objeto;

    // if (hoursToExpire === undefined) hoursToExpire = 24;
    //else hoursToExpire = parseInt(hoursToExpire);

    var date = new Date();
    date.setTime(date.getTime() + hoursToExpire * 60 * 60 * 1000);
    var expires = "; expires=" + date.toUTCString();

    document.cookie = key + "=" + value + ";" + expires + "; path=/";

});

app.ports.setCookie.subscribe((objeto) => {
    let key, value, monthsToExpire;
    [key, value, monthsToExpire] = objeto;

    if (monthsToExpire == undefined) monthsToExpire = 12;
    else monthsToExpire = parseInt(monthsToExpire);

    var date = new Date();
    date.setMonth(date.getMonth() + monthsToExpire);
    var expires = "; expires=" + date.toUTCString();

    document.cookie = key + "=" + value + ";" + expires + "; path=/";

});

app.ports.initGraph.subscribe((objeto) => {
    let elementId = objeto.elementId;
    let tipo = objeto.tipo;
    initChart(elementId, tipo);
});

app.ports.updateBarGraph.subscribe((objeto) => {
    let elementId = objeto.elementId;
    let labelList = objeto.axis;
    let datasetList = objeto.data;
    let tipo = objeto.tipo;
    let chart = Chart.getChart(elementId);
    
    if(!chart) {
        initChart(elementId, tipo);
        chart = Chart.getChart(elementId);
    }


    if(chart) {
        chart.data = {
            labels: datasetList.map(row => row.label),
            datasets: [{
                label: labelList[0],
                data: datasetList.map(row => row.value),
                borderColor: '#380169',
                    borderWidth: 1,
                backgroundColor: '#D7CCE1',
            }]
        }
        chart.update();
    }
});


app.ports.updatePieGraph.subscribe((objeto) => {
    let elementId = objeto.elementId;
    let labelList = objeto.axis;
    let datasetList = objeto.data;
    let tipo = objeto.tipo;
    let chart = Chart.getChart(elementId);
    
    if(!chart) {
        initChart(elementId, tipo);
        chart = Chart.getChart(elementId);
    }

    if(chart) {
        chart.data = {
            labels: datasetList.map(row => row.label),
            datasets: [{
                label: labelList[0],
                data: datasetList.map(row => row.value),
                // backgroundColor: ['#4C1A78', '#603487', '#744D96', '#8867a5', '#9C80B4', '#AF99C3', '#C3B3D2', '#D7CCE1', '#EBE6F0']
                backgroundColor: ['#9C80B4', '#AF99C3', '#C3B3D2', '#D7CCE1', '#EBE6F0']
            }]
        }
        chart.update();
    }
});


function initChart(elementId, type) {
    let canvas = document.getElementById(elementId);
    if(!canvas) {
        return;
    }

    let chart = Chart.getChart(elementId);
    if (chart === undefined) {
        new Chart(
            document.getElementById(elementId),    {
            type: type,
            optionas: {
                resposive: true
            }
        });
    }
}


function getCookie(name) {
    var nameEQ = name + "=";
    var ca = document.cookie.split(';');
    for (var i = 0; i < ca.length; i++) {
        var c = ca[i];
        while (c.charAt(0) == ' ') c = c.substring(1, c.length);
        if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
    }
    return null;
}