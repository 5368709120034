module Main exposing (main)

import Auth exposing (Status(..))
import Browser exposing (Document, UrlRequest)
import Browser.Navigation as Nav
import Configuracao.Menu as Configuracao exposing (..)
import Cookie as Cookie exposing (Msg)
import Dashboard exposing (..)
import EmailVerification
import Env
import Estatistica.Menu as Estatistica exposing (..)
import Funil exposing (Msg(..))
import Html exposing (..)
import Html.Attributes exposing (class)
import Http
import HttpBuilder
import Imprimir
import Json.Decode exposing (decodeValue)
import Json.Encode
import Lead exposing (Msg)
import Login
import Menu exposing (..)
import NotFound
import Politica as Politica exposing (Msg)
import Ports
import Preferencias.Main as Preferencias
import Projeto.Contato as Contato exposing (Msg)
import Projeto.Negociacao.Main as DetalheNegocio
import Projeto.Wizard.Main as Wizard
import Proposta.Wizard.Main as Proposta
import RemoteData exposing (RemoteData(..), WebData)
import Route exposing (Route(..))
import Termos exposing (Msg)
import Time exposing (Posix)
import Url exposing (Url)


type alias Model =
    { route : Route
    , redirectUrl : Maybe Url.Url
    , page : Page
    , navKey : Nav.Key
    , menu : Menu.Model
    , contato : Contato.Model
    , politica : Politica.Model
    , termos : Termos.Model
    , env : Env.Model
    , status : Auth.Status
    , loggedUser : Maybe Auth.User
    , expiresIn : Maybe Posix
    }


type Page
    = NotFoundPage
    | DashboardPage Dashboard.Model
    | ProjetoWizardPage Wizard.Model
    | ConfiguracaoPage Configuracao.Model
    | EstatisticaPage Estatistica.Model
    | DetalheNegocioPage DetalheNegocio.Model
    | PropostaPage Proposta.Model
    | FunilPage Funil.Model
    | ImprimirPage Imprimir.Model
    | LoginPage Login.Model
    | PreferenciasPage Preferencias.Model
    | PoliticaPage Politica.Model
    | TermosPage Termos.Model
    | LeadPage Lead.Model


type Msg
    = LinkClicked UrlRequest
    | UrlChanged Url
    | DashboardMsg Dashboard.Msg
    | MenuMsg Menu.Msg
    | ProjetoWizardMsg Wizard.Msg
    | EstatisticaMsg Estatistica.Msg
    | ConfiguracaoMsg Configuracao.Msg
    | DetalheNegocioMsg DetalheNegocio.Msg
    | ContatoMsg Contato.Msg
    | PropostaMsg Proposta.Msg
    | FunilMsg Funil.Msg
    | ImprimirMsg Imprimir.Msg
    | LoginMsg Login.Msg
    | PreferenciasMsg Preferencias.Msg
    | PoliticaMsg Politica.Msg
    | TermosMsg Termos.Msg
    | CookieMsg Cookie.Msg
    | LoggedInUserReceived Auth.Token (Maybe Url.Url) (WebData Auth.User)
    | DoRefresh
    | RefreshTokenSucceed Auth.AwsAuthResult
    | RefreshTokenFailed Http.Error
    | LeadMsg Lead.Msg


init : Json.Encode.Value -> Url -> Nav.Key -> ( Model, Cmd Msg )
init jsonEnv url navKey =
    let
        newEnv =
            case decodeValue Env.envDecoder jsonEnv of
                Ok env ->
                    env

                _ ->
                    Env.initEnv

        ( menuModel, menuCmds ) =
            Menu.init navKey newEnv

        ( contatoModel, contatoMsg ) =
            Contato.init navKey newEnv

        ( politicaModel, politicaMsg ) =
            Politica.init newEnv

        ( termosModel, termosMsg ) =
            Termos.init newEnv

        ( newStatus, statusCmd ) =
            initStatus newEnv (Just url)

        newRoute =
            case Route.parseUrl url of
                Imprimir hashProposta ->
                    Route.Imprimir hashProposta

                Politica ->
                    Route.Politica

                Termos ->
                    Route.Termos

                Login email fragment ->
                    Route.Login email fragment

                Lead ->
                    Route.Lead

                _ ->
                    case newStatus of
                        Auth.LoggedOut ->
                            Route.Login Nothing Nothing

                        _ ->
                            Route.parseUrl url

        -- se o usuário entrar com uma url inicial
        -- , depois do login redireciona para ela
        newRedirectUrl =
            case Route.parseUrl url of
                Route.Login _ _ ->
                    Nothing

                _ ->
                    Just url

        model =
            { route = newRoute
            , redirectUrl = newRedirectUrl
            , page = NotFoundPage
            , navKey = navKey
            , menu = menuModel
            , contato = contatoModel
            , politica = politicaModel
            , termos = termosModel
            , env = newEnv
            , status = newStatus
            , loggedUser = Nothing
            , expiresIn = Nothing
            }
    in
    initCurrentPage
        ( model
        , Cmd.batch
            [ Cmd.map MenuMsg menuCmds
            , Cmd.map ContatoMsg contatoMsg
            , Cmd.map PoliticaMsg politicaMsg
            , Cmd.map TermosMsg termosMsg
            , Cmd.map CookieMsg Cmd.none
            , statusCmd
            ]
        )


initStatus : Env.Model -> Maybe Url.Url -> ( Auth.Status, Cmd Msg )
initStatus env url =
    case env.access_token of
        Just token ->
            ( Auth.Unknown, fetchMe env token url )

        Nothing ->
            ( Auth.LoggedOut, Cmd.none )


fetchMe : Env.Model -> Auth.Token -> Maybe Url.Url -> Cmd Msg
fetchMe env token url =
    HttpBuilder.get (env.be_url ++ "/users/me")
        |> HttpBuilder.withBearerToken (Auth.getValue (Just token))
        |> HttpBuilder.withExpect
            (Auth.userDecoder
                |> Http.expectJson (RemoteData.fromResult >> LoggedInUserReceived token url)
            )
        |> HttpBuilder.request


initCurrentPage : ( Model, Cmd Msg ) -> ( Model, Cmd Msg )
initCurrentPage ( model, existingCmds ) =
    let
        ( currentPage, mappedPageCmds ) =
            case model.route of
                Route.NotFound ->
                    ( NotFoundPage, Cmd.none )

                Route.Dashboard _ ->
                    let
                        ( pageModel, pageCmd ) =
                            Dashboard.init model.env
                    in
                    ( DashboardPage pageModel, Cmd.map DashboardMsg pageCmd )

                Route.Projeto contatoId fragment ->
                    let
                        ( projetoModel, projetoCmd ) =
                            Wizard.init contatoId fragment model.navKey model.env
                    in
                    ( ProjetoWizardPage projetoModel, Cmd.map ProjetoWizardMsg projetoCmd )

                Route.Estatistica fragment ->
                    let
                        ( pageModel, pageCmd ) =
                            Estatistica.init model.env fragment
                    in
                    ( EstatisticaPage pageModel, Cmd.map EstatisticaMsg pageCmd )

                Route.Configuracao fragment ->
                    let
                        loggedUserId =
                            case model.loggedUser of
                                Just user ->
                                    user.id

                                Nothing ->
                                    -1

                        ( pageModel, pageCmd ) =
                            Configuracao.init model.env loggedUserId fragment
                    in
                    ( ConfiguracaoPage pageModel, Cmd.map ConfiguracaoMsg pageCmd )

                Route.Negociacao projetoId fragment ->
                    let
                        ( pageModel, pageCmd ) =
                            DetalheNegocio.init
                                (Maybe.withDefault -1 projetoId)
                                model.navKey
                                fragment
                                model.env
                    in
                    ( DetalheNegocioPage pageModel, Cmd.map DetalheNegocioMsg pageCmd )

                Route.Proposta propostaId projetoId ->
                    let
                        ( pageModel, pageCmd ) =
                            Proposta.init
                                (Maybe.withDefault -1 projetoId)
                                (Maybe.withDefault -1 propostaId)
                                model.navKey
                                model.env
                    in
                    ( PropostaPage pageModel, Cmd.map PropostaMsg pageCmd )

                Route.Funil fase ->
                    let
                        ( pageModel, pageCmd ) =
                            Funil.init model.env fase
                    in
                    ( FunilPage pageModel, Cmd.map FunilMsg pageCmd )

                Route.Imprimir hashProposta ->
                    let
                        ( pageModel, pageCmd ) =
                            Imprimir.init hashProposta
                                model.env
                    in
                    ( ImprimirPage pageModel, Cmd.map ImprimirMsg pageCmd )

                Route.Login email fragment ->
                    let
                        ( pageModel, pageCmd ) =
                            Login.init model.navKey model.env email fragment
                    in
                    ( LoginPage pageModel, Cmd.map LoginMsg pageCmd )

                Route.Preferencias fragment ->
                    let
                        ( pageModel, pageCmd ) =
                            Preferencias.init model.loggedUser model.env fragment
                    in
                    ( PreferenciasPage pageModel, Cmd.map PreferenciasMsg pageCmd )

                Route.Politica ->
                    let
                        ( pageModel, pageCmd ) =
                            Politica.init model.env

                        --fragment
                    in
                    ( PoliticaPage pageModel, Cmd.map PoliticaMsg pageCmd )

                Route.Termos ->
                    let
                        ( pageModel, pageCmd ) =
                            Termos.init model.env

                        --fragment
                    in
                    ( TermosPage pageModel, Cmd.map TermosMsg pageCmd )

                Route.Lead ->
                    let
                        ( pageModel, pageCmd ) =
                            Lead.init model.env
                    in
                    ( LeadPage pageModel, Cmd.map LeadMsg pageCmd )
    in
    ( { model | page = currentPage }
    , Cmd.batch [ existingCmds, mappedPageCmds ]
    )


update : Msg -> Model -> ( Model, Cmd Msg )
update msg model =
    case ( msg, model.page ) of
        ( LinkClicked urlRequest, _ ) ->
            case urlRequest of
                Browser.Internal url ->
                    ( model
                    , Route.pushUrl (Route.parseUrl url) model.navKey
                    )

                Browser.External url ->
                    ( model
                    , Nav.load url
                    )

        ( UrlChanged url, ProjetoWizardPage pageModel ) ->
            case url.fragment of
                Just value ->
                    let
                        newRoute =
                            Route.parseUrl url

                        -- atualiza id do contato para selecionar na lista
                        newPageModel =
                            case newRoute of
                                Projeto contatoId _ ->
                                    Wizard.updateContatoId pageModel contatoId

                                _ ->
                                    pageModel

                        ( updatedPageModel, updatedCmd ) =
                            Wizard.update (Wizard.UrlChanged value) newPageModel
                    in
                    ( { model | page = ProjetoWizardPage updatedPageModel }
                    , Cmd.map ProjetoWizardMsg updatedCmd
                    )

                Nothing ->
                    let
                        newRoute =
                            Route.parseUrl url
                    in
                    ( { model | route = newRoute, redirectUrl = Nothing }, Cmd.none )
                        |> initCurrentPage

        ( UrlChanged url, _ ) ->
            let
                newRoute =
                    --Route.parseUrl url
                    redirectIfNotAllowed model.loggedUser (Route.parseUrl url)

                newModel =
                    ( { model | route = newRoute, redirectUrl = Nothing }, Cmd.none )
            in
            case newRoute of
                Route.Imprimir _ ->
                    newModel

                --Route.Politica ->
                --    newModel
                _ ->
                    newModel |> initCurrentPage

        ( DashboardMsg subMsg, DashboardPage pageModel ) ->
            let
                ( updatedPageModel, updatedCmd ) =
                    Dashboard.update subMsg pageModel

                -- pesquisa =
                --    updatedPageModel.pesquisa
                -- updatedEnv = pesquisa.env
                newEnv =
                    updatedPageModel.env
            in
            ( { model | page = DashboardPage updatedPageModel, env = newEnv }
            , Cmd.map DashboardMsg updatedCmd
            )

        ( ProjetoWizardMsg subMsg, ProjetoWizardPage pageModel ) ->
            let
                ( updatedPageModel, updatedCmd ) =
                    Wizard.update subMsg pageModel
            in
            ( { model | page = ProjetoWizardPage updatedPageModel }
            , Cmd.map ProjetoWizardMsg updatedCmd
            )

        ( ContatoMsg contatoMsg, ProjetoWizardPage pageModel ) ->
            let
                ( updatedModel, updatedCmd ) =
                    Contato.update contatoMsg model.contato

                ( updatedPageModel, updatedPageCmd ) =
                    case contatoMsg of
                        Contato.ContatoCreated (Ok contato) ->
                            Wizard.update (Wizard.ReloadContatos contato.id) pageModel

                        _ ->
                            ( pageModel, Cmd.none )
            in
            ( { model
                | contato = updatedModel
                , page = ProjetoWizardPage updatedPageModel
              }
            , Cmd.batch
                [ Cmd.map ContatoMsg updatedCmd
                , Cmd.map ProjetoWizardMsg updatedPageCmd
                ]
            )

        ( ContatoMsg contatoMsg, FunilPage pageModel ) ->
            let
                ( updatedModel, updatedCmd ) =
                    Contato.update contatoMsg model.contato

                ( updatedPageModel, updatedPageCmd ) =
                    case contatoMsg of
                        Contato.ContatoCreated (Ok _) ->
                            Funil.update Funil.ReloadClientes pageModel

                        _ ->
                            ( pageModel, Cmd.none )
            in
            ( { model
                | contato = updatedModel
                , page = FunilPage updatedPageModel
              }
            , Cmd.batch
                [ Cmd.map ContatoMsg updatedCmd
                , Cmd.map FunilMsg updatedPageCmd
                ]
            )

        ( ContatoMsg subMsg, _ ) ->
            case subMsg of
                _ ->
                    let
                        ( contatoModel, contatoMsg ) =
                            Contato.update subMsg model.contato
                    in
                    ( { model | contato = contatoModel }, Cmd.map ContatoMsg contatoMsg )

        ( MenuMsg (Menu.Logout as subMsg), _ ) ->
            let
                ( updatedModel, updatedCmd ) =
                    Menu.update subMsg model.menu

                env =
                    model.env

                newEnv =
                    { env | access_token = Nothing, refresh_token = Nothing }

                ( newModel, newCmd ) =
                    logout model
            in
            ( { newModel | menu = updatedModel, env = newEnv }
            , Cmd.batch [ Cmd.map MenuMsg updatedCmd, newCmd ]
            )
                |> initCurrentPage

        ( MenuMsg subMsg, _ ) ->
            let
                ( updatedModel, updatedCmd ) =
                    Menu.update subMsg model.menu
            in
            ( { model | menu = updatedModel }
            , Cmd.map MenuMsg updatedCmd
            )

        ( ConfiguracaoMsg subMsg, ConfiguracaoPage pageModel ) ->
            let
                ( updatedPageModel, updatedCmd ) =
                    Configuracao.update subMsg pageModel
            in
            ( { model | page = ConfiguracaoPage updatedPageModel }
            , Cmd.map ConfiguracaoMsg updatedCmd
            )

        ( EstatisticaMsg subMsg, EstatisticaPage pageModel ) ->
            let
                ( updatedPageModel, updatedCmd ) =
                    Estatistica.update subMsg pageModel
            in
            ( { model | page = EstatisticaPage updatedPageModel }
            , Cmd.map EstatisticaMsg updatedCmd
            )

        ( DetalheNegocioMsg subMsg, DetalheNegocioPage pageModel ) ->
            let
                ( updatedPageModel, updatedCmd ) =
                    DetalheNegocio.update subMsg pageModel
            in
            ( { model | page = DetalheNegocioPage updatedPageModel }
            , Cmd.map DetalheNegocioMsg updatedCmd
            )

        ( PropostaMsg subMsg, PropostaPage pageModel ) ->
            let
                ( updatedPageModel, updatedCmd ) =
                    Proposta.update subMsg pageModel
            in
            ( { model | page = PropostaPage updatedPageModel }
            , Cmd.map PropostaMsg updatedCmd
            )

        ( FunilMsg subMsg, FunilPage pageModel ) ->
            let
                ( updatedPageModel, updatedCmd ) =
                    Funil.update subMsg pageModel
            in
            ( { model | page = FunilPage updatedPageModel }
            , Cmd.map FunilMsg updatedCmd
            )

        ( ImprimirMsg subMsg, ImprimirPage pageModel ) ->
            let
                ( updatedPageModel, updatedCmd ) =
                    Imprimir.update subMsg pageModel
            in
            ( { model | page = ImprimirPage updatedPageModel }
            , Cmd.map ImprimirMsg updatedCmd
            )

        ( PoliticaMsg subMsg, PoliticaPage pageModel ) ->
            let
                ( updatedPageModel, updatedCmd ) =
                    Politica.update subMsg pageModel
            in
            ( { model | page = PoliticaPage updatedPageModel }
            , Cmd.map PoliticaMsg updatedCmd
            )

        ( PoliticaMsg subMsg, _ ) ->
            case subMsg of
                _ ->
                    let
                        ( politicaModel, politicaMsg ) =
                            Politica.update subMsg model.politica
                    in
                    ( { model | politica = politicaModel }, Cmd.map PoliticaMsg politicaMsg )

        ( TermosMsg subMsg, _ ) ->
            case subMsg of
                _ ->
                    let
                        ( termosModel, termosMsg ) =
                            Termos.update subMsg model.termos
                    in
                    ( { model | termos = termosModel }, Cmd.map TermosMsg termosMsg )

        ( CookieMsg subMsg, _ ) ->
            case subMsg of
                _ ->
                    let
                        ( newEnv, cookieMsg ) =
                            Cookie.update subMsg model.env
                    in
                    ( { model | env = newEnv }, Cmd.map CookieMsg cookieMsg )

        ( LoginMsg ((Login.LoginReceived authMsg) as subMsg), LoginPage pageModel ) ->
            let
                ( updatedPageModel, updatedCmd ) =
                    Login.update subMsg pageModel

                env =
                    model.env

                ( newEnv, newCmd, expiresIn ) =
                    case authMsg of
                        Success awsData ->
                            let
                                accessToken =
                                    Auth.Token awsData.accessToken
                            in
                            ( updateEnv env awsData
                            , fetchMe env accessToken model.redirectUrl
                            , Just awsData.expiresIn
                            )

                        _ ->
                            ( env, Cmd.none, Nothing )
            in
            ( { model | page = LoginPage updatedPageModel, env = newEnv, expiresIn = expiresIn }
            , Cmd.batch
                [ Cmd.map LoginMsg updatedCmd
                , newCmd
                ]
            )

        ( LoginMsg ((Login.UpdateEmailVerification (EmailVerification.NewUserReceivedAndLoggedIn authMsg)) as subMsg), LoginPage pageModel ) ->
            let
                ( updatedPageModel, updatedCmd ) =
                    Login.update subMsg pageModel

                env =
                    model.env

                ( newEnv, newCmd, expiresIn ) =
                    case authMsg of
                        Success awsData ->
                            let
                                accessToken =
                                    Auth.Token awsData.accessToken
                            in
                            ( updateEnv env awsData
                            , fetchMe env accessToken model.redirectUrl
                            , Just awsData.expiresIn
                            )

                        _ ->
                            ( env, Cmd.none, Nothing )
            in
            ( { model | page = LoginPage updatedPageModel, env = newEnv, expiresIn = expiresIn }
            , Cmd.batch
                [ Cmd.map LoginMsg updatedCmd
                , newCmd
                ]
            )

        ( LoginMsg subMsg, LoginPage pageModel ) ->
            let
                ( updatedPageModel, updatedCmd ) =
                    Login.update subMsg pageModel
            in
            ( { model | page = LoginPage updatedPageModel }
            , Cmd.map LoginMsg updatedCmd
            )

        -- busca informações do usuário atual
        ( LoggedInUserReceived token url response, _ ) ->
            case response of
                Success user ->
                    let
                        routeCmd =
                            case url of
                                Just value ->
                                    -- verifica se a rota atual é a mesma que está se tentando redirecionar
                                    -- se a rota é a mesma, não é necessário recarregar com o Route.pushUrl
                                    if Route.parseUrl value == model.route then
                                        Cmd.none

                                    else
                                        Route.pushUrl (Route.parseUrl value) model.navKey

                                Nothing ->
                                    redirectToInicio model.navKey

                        newLoggerUser =
                            Just user

                        cmdExibePoliticaPrivacidade =
                            Cmd.map PoliticaMsg (Politica.exibeModalComDelay (not user.isPolicyAccepted))

                        cmdExibeTermos =
                            Cmd.map TermosMsg (Termos.exibeModalComDelay (not user.isTermsAccepted))

                        env =
                            model.env

                        newEnv =
                            { env | has_essencial_plan = user.hasEssencial, has_lead_plan = user.hasLeadPlan }

                        newPage =
                            updateEnvOnPages newEnv model.page

                        -- cmdFunil = Cmd.map FunilMsg (Funil.usuarioLogado <| Maybe.withDefault defaultUser newLoggerUser)
                    in
                    ( { model
                        | status = Auth.LoggedIn token
                        , loggedUser = newLoggerUser
                        , menu = Menu.updateLoggedUser newLoggerUser model.menu
                        , contato = updateEnvOnContato newEnv model.contato
                        , politica = updateEnvOnPolitica newEnv model.politica
                        , termos = updateEnvOnTermos newEnv model.termos
                        , env = newEnv
                        , page = newPage
                      }
                    , Cmd.batch
                        [ routeCmd
                        , refreshTokenCmd model.expiresIn
                        , cmdExibePoliticaPrivacidade
                        , cmdExibeTermos

                        --  , cmdFunil
                        ]
                    )

                -- se falhar a busca, limpa o token
                Failure _ ->
                    logout model

                _ ->
                    ( model, Cmd.none )

        ( DoRefresh, _ ) ->
            let
                newCmd =
                    case model.status of
                        LoggedIn _ ->
                            Auth.refresh model.env.refresh_token handleRefreshToken

                        _ ->
                            Cmd.none
            in
            ( model, newCmd )

        ( RefreshTokenSucceed awsData, _ ) ->
            let
                newEnv =
                    updateEnv model.env awsData

                expiresIn =
                    Just awsData.expiresIn

                newPage =
                    updateEnvOnPages newEnv model.page

                newContatoModel =
                    updateEnvOnContato newEnv model.contato
            in
            refresh model newEnv newContatoModel newPage expiresIn

        ( RefreshTokenFailed _, _ ) ->
            logout model |> initCurrentPage

        ( PreferenciasMsg subMsg, PreferenciasPage pageModel ) ->
            let
                ( updatedPageModel, updatedCmd ) =
                    Preferencias.update subMsg pageModel
            in
            ( { model | page = PreferenciasPage updatedPageModel }
            , Cmd.map PreferenciasMsg updatedCmd
            )

        ( LeadMsg subMsg, LeadPage pageModel ) ->
            let
                ( updatedPageModel, updatedCmd ) =
                    Lead.update subMsg pageModel
            in
            ( { model | page = LeadPage updatedPageModel }
            , Cmd.map LeadMsg updatedCmd
            )

        ( _, _ ) ->
            ( model, Cmd.none )


redirectIfNotAllowed : Maybe Auth.User -> Route -> Route
redirectIfNotAllowed loggerdUser route =
    case loggerdUser of
        Just user ->
            case route of
                Route.Configuracao fragment ->
                    if user.isAdmin then
                        Route.Configuracao fragment

                    else
                        Route.NotFound

                _ ->
                    route

        _ ->
            Route.Login Nothing Nothing


updateEnv : Env.Model -> Auth.AwsAuthResult -> Env.Model
updateEnv env awsData =
    let
        accessToken =
            Auth.Token awsData.accessToken

        refreshToken =
            case awsData.refreshToken of
                Nothing ->
                    env.refresh_token

                Just value ->
                    Just (Auth.Token value)
    in
    { env
        | access_token = Just accessToken
        , refresh_token = refreshToken
    }


updateEnvOnContato : Env.Model -> Contato.Model -> Contato.Model
updateEnvOnContato env contatoModel =
    { contatoModel | env = env }


updateEnvOnPolitica : Env.Model -> Politica.Model -> Politica.Model
updateEnvOnPolitica env politicaModel =
    { politicaModel | env = env }


updateEnvOnTermos : Env.Model -> Termos.Model -> Termos.Model
updateEnvOnTermos env termosModel =
    { termosModel | env = env }


updateEnvOnPages : Env.Model -> Page -> Page
updateEnvOnPages env page =
    case page of
        ImprimirPage pageModel ->
            ImprimirPage { pageModel | env = env }

        NotFoundPage ->
            NotFoundPage

        DashboardPage pageModel ->
            DashboardPage { pageModel | env = env }

        ProjetoWizardPage pageModel ->
            ProjetoWizardPage { pageModel | env = env }

        EstatisticaPage pageModel ->
            EstatisticaPage { pageModel | env = env }

        ConfiguracaoPage pageModel ->
            ConfiguracaoPage { pageModel | env = env }

        DetalheNegocioPage pageModel ->
            DetalheNegocioPage { pageModel | env = env }

        PropostaPage pageModel ->
            PropostaPage { pageModel | env = env }

        FunilPage pageModel ->
            FunilPage { pageModel | env = env }

        LoginPage pageModel ->
            LoginPage { pageModel | env = env }

        PreferenciasPage pageModel ->
            PreferenciasPage { pageModel | env = env }

        PoliticaPage pageModel ->
            PoliticaPage { pageModel | env = env }

        TermosPage pageModel ->
            TermosPage { pageModel | env = env }

        LeadPage pageModel ->
            LeadPage { pageModel | env = env }


refreshTokenCmd : Maybe Posix -> Cmd Msg
refreshTokenCmd expiresIn =
    Auth.delayedCmd
        (Maybe.withDefault (Time.millisToPosix (10 * 1000)) expiresIn)
        DoRefresh


handleRefreshToken : RemoteData Http.Error Auth.AwsAuthResult -> Msg
handleRefreshToken response =
    case response of
        Success awsData ->
            RefreshTokenSucceed awsData

        Failure status ->
            RefreshTokenFailed status

        _ ->
            RefreshTokenFailed (Http.BadStatus 500)


redirectToInicio : Nav.Key -> Cmd Msg
redirectToInicio navKey =
    Nav.pushUrl navKey "/inicio"


redirectToLogin : Nav.Key -> Cmd Msg
redirectToLogin navKey =
    Nav.pushUrl navKey "/login"


logout : Model -> ( Model, Cmd Msg )
logout model =
    ( { model | expiresIn = Nothing, status = LoggedOut, loggedUser = Nothing, route = Route.Login Nothing Nothing }
    , Cmd.batch
        [ Ports.clearAccessTokenFromStorage ()
        , Ports.clearRefreshTokenFromStorage ()
        , redirectToLogin model.navKey
        ]
    )


refresh : Model -> Env.Model -> Contato.Model -> Page -> Maybe Posix -> ( Model, Cmd Msg )
refresh model env contato page expiresIn =
    ( { model
        | env = env
        , expiresIn = expiresIn
        , page = page
        , contato = contato
      }
    , Cmd.batch
        [ refreshTokenCmd expiresIn
        , Ports.sendAccessTokenToStorage (Auth.getValue env.access_token)
        , Ports.sendRefreshTokenToStorage (Auth.getValue env.refresh_token)
        ]
    )



-- VIEW


view : Model -> Document Msg
view model =
    { title = "Ésses CRM"
    , body = [ currentView model ]
    }


currentView : Model -> Html Msg
currentView model =
    let
        isPolicyAccepted =
            case model.loggedUser of
                Just user ->
                    user.isPolicyAccepted

                Nothing ->
                    True

        isTermsAccepted =
            case model.loggedUser of
                Just user ->
                    user.isTermsAccepted

                Nothing ->
                    True

        viewPoliticaPrivacidade =
            if isPolicyAccepted then
                []

            else
                [ Politica.viewModalPolitica model.politica
                    |> Html.map PoliticaMsg
                ]

        viewTermosUso =
            if isTermsAccepted then
                []

            else
                [ Termos.viewModalTermos model.termos
                    |> Html.map TermosMsg
                ]

        header =
            case model.page of
                ImprimirPage _ ->
                    [ Cookie.view model.env
                        |> Html.map CookieMsg
                    ]

                PoliticaPage _ ->
                    [ Cookie.view model.env
                        |> Html.map CookieMsg
                    ]

                TermosPage _ ->
                    [ Cookie.view model.env
                        |> Html.map CookieMsg
                    ]

                LoginPage _ ->
                    [ Cookie.view model.env
                        |> Html.map CookieMsg
                    ]

                LeadPage _ ->
                    [ Cookie.view model.env
                        |> Html.map CookieMsg
                    ]

                _ ->
                    [ Contato.view model.contato
                        |> Html.map ContatoMsg
                    , viewMenu model.menu

                    -- , Politica.viewModalPolitica model.politica
                    --     |> Html.map PoliticaMsg
                    --  , Termos.viewModalTermos model.termos
                    --      |> Html.map TermosMsg
                    ]
                        ++ viewPoliticaPrivacidade
                        ++ viewTermosUso
                        ++ [ Cookie.view model.env
                                |> Html.map CookieMsg
                           ]
    in
    div []
        (header
            ++ [ div
                    [ class "container-fluid" ]
                    [ case model.page of
                        NotFoundPage ->
                            notFoundView

                        DashboardPage pageModel ->
                            viewDashboard pageModel

                        ProjetoWizardPage pageModel ->
                            viewProjetoWizard pageModel

                        EstatisticaPage pageModel ->
                            viewEstatistica pageModel

                        ConfiguracaoPage pageModel ->
                            viewConfiguracao pageModel

                        DetalheNegocioPage pageModel ->
                            viewDetalheNegocio pageModel

                        PropostaPage pageModel ->
                            viewProposta pageModel

                        FunilPage pageModel ->
                            viewFunil pageModel

                        ImprimirPage pageModel ->
                            viewImprimir pageModel

                        LoginPage pageModel ->
                            viewLogin pageModel

                        PreferenciasPage pageModel ->
                            viewPreferencias pageModel

                        PoliticaPage _ ->
                            viewPolitica

                        TermosPage _ ->
                            viewTermos

                        LeadPage pageModel ->
                            viewLead pageModel
                    ]
               ]
        )


viewMenu : Menu.Model -> Html Msg
viewMenu model =
    Menu.view model
        |> Html.map MenuMsg


viewDashboard : Dashboard.Model -> Html Msg
viewDashboard pageModel =
    Dashboard.view pageModel
        |> Html.map DashboardMsg


viewProjetoWizard : Wizard.Model -> Html Msg
viewProjetoWizard pageModel =
    Wizard.view pageModel
        |> Html.map ProjetoWizardMsg


viewConfiguracao : Configuracao.Model -> Html Msg
viewConfiguracao pageModel =
    Configuracao.view pageModel
        |> Html.map ConfiguracaoMsg


viewEstatistica : Estatistica.Model -> Html Msg
viewEstatistica pageModel =
    Estatistica.view pageModel
        |> Html.map EstatisticaMsg


viewDetalheNegocio : DetalheNegocio.Model -> Html Msg
viewDetalheNegocio pageModel =
    DetalheNegocio.view pageModel
        |> Html.map DetalheNegocioMsg


viewProposta : Proposta.Model -> Html Msg
viewProposta pageModel =
    Proposta.view pageModel
        |> Html.map PropostaMsg


viewFunil : Funil.Model -> Html Msg
viewFunil pageModel =
    Funil.view pageModel
        |> Html.map FunilMsg


viewImprimir : Imprimir.Model -> Html Msg
viewImprimir pageModel =
    Imprimir.view pageModel
        |> Html.map ImprimirMsg


viewPolitica : Html Msg
viewPolitica =
    Politica.view
        |> Html.map PoliticaMsg


viewTermos : Html Msg
viewTermos =
    Termos.view
        |> Html.map TermosMsg


viewLead : Lead.Model -> Html Msg
viewLead leadModel =
    Lead.view leadModel
        |> Html.map LeadMsg


viewLogin : Login.Model -> Html Msg
viewLogin pageModel =
    Login.view pageModel
        |> Html.map LoginMsg


viewPreferencias : Preferencias.Model -> Html Msg
viewPreferencias pageModel =
    Preferencias.view pageModel
        |> Html.map PreferenciasMsg


notFoundView : Html msg
notFoundView =
    NotFound.view


subscriptions : Model -> Sub Msg
subscriptions _ =
    Sub.none


main : Program Json.Encode.Value Model Msg
main =
    Browser.application
        { init = init
        , view = view
        , update = update
        , subscriptions = subscriptions
        , onUrlRequest = LinkClicked
        , onUrlChange = UrlChanged
        }



{--defaultUser : Auth.User
defaultUser =
    { id = -1
    , name = ""
    , isAdmin = False
    , email = "String"
    , isPolicyAccepted = False
    , isTermsAccepted = False
    , visualizaTodasNegociacoes = False
    }--}
